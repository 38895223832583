import { ProtoTypes } from "../constants/prototypes";

export const getSanitizedData = ({
  data,
  content_type,
}: {
  data: any;
  content_type: string;
}) => {
  // Recursive function to sanitize each item in the data array
  const getSanitizeItem = (item: any) => {
    const fields = item.fields;
    const keys = Object.keys(fields);
    const sanitizeItem: any = {};
    keys.forEach((key) => {
      const value = fields[key];
      if (Object.prototype.toString.call(value) === ProtoTypes.object) {
        // If the value is an object and has fields, recursively sanitize it
        if (value?.fields) {
          sanitizeItem[key] = getSanitizeItem(value);
        } else {
          // Otherwise, assign the value as is
          sanitizeItem[key] = value;
        }
      } else if (Object.prototype.toString.call(value) === ProtoTypes.array) {
        // If the value is an array, sanitize each item in the array
        sanitizeItem[key] = value.map((item: any) => {
          if (Object.prototype.toString.call(item) === ProtoTypes.object) {
            // If the item is an object, recursively sanitize it
            return getSanitizeItem(item);
          } else if (item?.fields) {
            // If the item has fields, recursively sanitize it
            return getSanitizeItem(item);
          } else {
            // Otherwise, assign the item as is
            return item;
          }
        });
      } else {
        // Otherwise, assign the value as is
        sanitizeItem[key] = value;
      }
    });
    return sanitizeItem;
  };

  // Sanitize each item in the data array
  const result = data.map((item: any, index: number) => {
    const data = getSanitizeItem(item);
    const sectionId = content_type + (index ? index : "");
    const updatedData = { ...data, sectionId };
    return updatedData;
  });

  // Return the sanitized data
  return result;
};
