import { Container } from "../../../components/Container";
import { Logo } from "../../../components/Logo";
import { VerticalSeparator } from "../../../components/Separator";
import Text from "../../../components/Text";
import { Colors, TextTypes } from "../../../constants/default";
import useIsMobile from "../../../hooks/useMobile";
import { Item } from "../../../types";

export const DetailsCardItem = ({ item }: { item: Item }) => {
  const isMobile = useIsMobile();

  // Container to hold the logo and text content
  return (
    <Container
      BG={Colors.None}
      alignItems={"center"}
      gap={isMobile ? 10 : 20}
      marginLeft={10}
      marginRight={10}
    >
      {/* Logo component */}
      <Container
        BG={Colors.PrimaryCharcoal}
        borderRadius="50%"
        width={isMobile ? "54px" : "74px"}
        height={isMobile ? "54px" : "74px"}
        paddingVertical={5}
        paddingHorizontal={5}
        zIndex={0}
        alignItems="center"
      >
        <Logo
          url={item.logo.file.url}
          width={isMobile ? 30 : 50}
          height={isMobile ? 30 : 50}
        />
      </Container>

      <Container BG={Colors.None} alignItems={"center"} gap={10}>
        {/* Text component for the title */}
        <Text
          type={
            isMobile ? TextTypes.BulletLiContent : TextTypes.DefaultCardTitle
          }
          color={Colors.PrimaryWine}
        >
          {item.title}
        </Text>

        {/* Text component for the subtitle */}
        <Text
          type={isMobile ? TextTypes.BulletLi : TextTypes.DefaultCardSubTitle}
          color={Colors.PrimaryWine}
        >
          {item.subTitle}
        </Text>
      </Container>
    </Container>
  );
};

export const DetailsCard = ({ ratingContent }: { ratingContent: Item[] }) => {
  const isMobile = useIsMobile();

  // Container to hold multiple DetailsCardItem components
  return (
    <Container
      gap={isMobile ? 30 : 122}
      BG={Colors.None}
      flexDirection={"row"}
      marginTop={isMobile ? 0 : 124}
      margin={isMobile ? 20 : 0}
      mobileStyles={{ flexDirection: "column", gap: 20 }}
    >
      {/* Map through the ratingContent array and render DetailsCardItem components */}
      {ratingContent.map((item, index) => {
        return (
          <>
            {/* Render DetailsCardItem component */}
            <DetailsCardItem item={item} />

            {/* Render VerticalSeparator component if not the last item */}
            {!isMobile && index < ratingContent.length - 1 ? (
              <VerticalSeparator color={Colors.PrimaryWine} />
            ) : null}
          </>
        );
      })}
    </Container>
  );
};
