import { Container } from "../../../components/Container";
import { Logo } from "../../../components/Logo";
import Text from "../../../components/Text";
import { Colors, TextTypes } from "../../../constants/default";
import { Item } from "../../../types";
import styles from "./styles.module.scss";

// Component for rendering each individual footer item
const FooterItem = ({ logo, title }: Item) => {
  return (
    <div
      className={styles.footerItem}
      style={{
        borderColor: Colors.FooterBorderTopColor,
      }}
    >
      <Logo url={logo.file.url} /> {/* Render the logo */}
      <Text
        type={TextTypes.FooterTitle}
        color={Colors.FooterTitle}
        verticalAlign="center"
      >
        {title} {/* Render the title */}
      </Text>
    </div>
  );
};

// Main Footer component
export const Footer = ({ footerTab }: { footerTab: Item[] }) => {
  return (
    <Container
      flexDirection="row"
      gap={56}
      justifyContent="space-between"
      paddingHorizontal={100}
      paddingBottom={50}
      marginTop={100}
      mobileStyles={{
        paddingHorizontal: 50,
        paddingBottom: 52,
        flexWrap: "wrap",
        gap: 23,
      }}
    >
      {footerTab.map(({ logo, title }, index) => (
        <FooterItem key={index} logo={logo} title={title} /> // Render each footer item
      ))}
    </Container>
  );
};
