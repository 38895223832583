import { useCallback, useEffect, useRef, useState } from "react";
import { Container } from "../../../components/Container";
import { ColorPalette, TabList as TabListProps } from "../types";
import {
  Colors,
  TabListSwitchTimer,
  TextTypes,
} from "../../../constants/default";
import Text from "../../../components/Text";

import styled, { keyframes, css } from "styled-components";

// Styles for the active tab
const ActiveTabStyles = {
  backgroundColor: Colors.PrimaryWhite,
};
const ActiveTabStylesBorder = {
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
};

// Props for the line container
interface LineContainerProps {
  BG?: string; // Optional prop for background color
}

// Keyframe animation for growing the line
const growLine = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

// Styled component for the line container
const LineContainer = styled.div<LineContainerProps>`
  position: relative;
  width: 100%;
  height: 2px; // Adjust the thickness of the line
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: ${(props) => props.BG || "#f0f0f0"};
    animation: ${growLine} 8s ease-in-out forwards;
  }
`;

// CSS for fade-in effect
const fadeIn = css`
  transition: background-color 0.5s ease-in; // Fade-in effect
`;

// CSS for fade-out effect
const fadeOut = css`
  transition: background-color 0.5s ease-out; // Fade-out effect
`;

// Props for the tab title container
interface TabTitleContainerProps {
  BG?: string; // Optional prop for background color
  isActive: boolean;
}

// Styled component for the tab title container
const TabTitleContainer = styled.div<TabTitleContainerProps>`
  position: relative;
  background-color: ${(props) =>
    props.isActive ? Colors.PrimaryWhite : props.BG};
  ${(props) => (props.isActive ? fadeIn : fadeOut)}
`;

// Component for the tab list
export const TabList = ({
  tabList,
  colorPalette,
}: {
  tabList: TabListProps[];
  colorPalette: ColorPalette;
}) => {
  const timerRef: any = useRef();

  // Function to set the timer for switching tabs
  const setTimer = useCallback(
    (activeTab: number) => {
      if (activeTab + 1 < tabList.length) {
        timerRef.current = setTimeout(() => {
          setActiveTab(activeTab + 1);
          setTimer(activeTab + 1);
        }, TabListSwitchTimer);
      } else {
        timerRef.current = setTimeout(() => {
          setActiveTab(0);
          setTimer(0);
        }, TabListSwitchTimer);
      }
    },
    [tabList.length]
  );

  // State for the active tab
  const [activeTab, setActiveTab] = useState(0);

  // Destructuring the first tab from the tab list
  const [firstTab, ...restTabList] = tabList;

  // Effect to start the timer when the component mounts
  useEffect(() => {
    if (!timerRef.current) {
      setTimer(0);
    }
  }, [setTimer]);

  // Rendering the tab list
  return (
    <Container
      BG={colorPalette.primary}
      borderRadius="40px"
      flexDirection="row"
      boxShadow={1}
      mobileStyles={{
        flexWrap: "wrap",
        gap: 30,
      }}
    >
      <Container flexBasis={"33.33%"} flexGrow={1} alignSelf="stretch">
        <TabTitleContainer
          BG={colorPalette.primary}
          isActive={activeTab === 0}
          style={{
            ...ActiveTabStylesBorder,
            ...(activeTab === 0
              ? ActiveTabStyles
              : {
                  backgroundColor: colorPalette.primary,
                }),
          }}
        >
          <Container
            paddingHorizontal={49}
            paddingVertical={20}
            flexShrink={1}
            alignSelf="stretch"
            position="relative"
          >
            <Text
              type={TextTypes.TabListTitle}
              color={colorPalette.secondary as Colors}
            >
              {firstTab.title}
            </Text>
            {activeTab === 0 ? (
              <LineContainer BG={colorPalette.secondary} />
            ) : null}
          </Container>
        </TabTitleContainer>

        <Container
          BG={Colors.PrimaryWhite}
          paddingHorizontal={49}
          paddingVertical={59}
          paddingTop={37}
          flexGrow={1}
          justifyContent="flex-start"
          borderBottomLeftRadius="40px"
          mobileStyles={{
            borderBottomLeftRadius: "0px",
          }}
        >
          {firstTab.listDetails?.map((listItem, index) => (
            <Text
              type={TextTypes.BulletLiFirst}
              color={colorPalette.secondary as Colors}
            >
              {listItem}
            </Text>
          ))}
        </Container>
      </Container>
      {restTabList.map((tab, tabIndex) => {
        const { title, listDetails } = tab;
        const activeTabStyles =
          activeTab > 0 && activeTab === tabIndex + 1
            ? ActiveTabStyles
            : {
                backgroundColor: colorPalette.primary,
              };

        const isLastTab = tabList.length - 1 === tabIndex + 1;
        return (
          <Container flexBasis={"33.33%"} flexGrow={1} alignSelf="stretch">
            <TabTitleContainer
              BG={colorPalette.primary}
              isActive={activeTab > 0 && activeTab === tabIndex + 1}
              style={{ ...ActiveTabStylesBorder, ...activeTabStyles }}
            >
              <Container
                paddingHorizontal={49}
                paddingVertical={20}
                alignSelf="stretch"
                position="relative"
              >
                <Text
                  type={TextTypes.TabListTitle}
                  color={colorPalette.secondary as Colors}
                >
                  {title}
                </Text>
                {activeTab > 0 && activeTab === tabIndex + 1 ? (
                  <LineContainer BG={colorPalette.secondary} />
                ) : null}
              </Container>
            </TabTitleContainer>

            <Container
              alignSelf="stretch"
              paddingHorizontal={49}
              paddingVertical={59}
              flexGrow={1}
              BG={Colors.PrimaryWhite}
              justifyContent="flex-start"
              borderBottomRightRadius={isLastTab ? "40px" : "0"}
              mobileStyles={{
                borderBottomRightRadius: "0",
                paddingHorizontal: 24.5,
                paddingVertical: 29.5,
              }}
            >
              {listDetails.map((listItem, index) => (
                <Container
                  flexDirection="row"
                  alignItems="center"
                  marginTop={index ? 37 : 0}
                  alignSelf="flex-start"
                  justifyContent="flex-start"
                  gap={20}
                >
                  <div>
                    <Container
                      BG={colorPalette.primary}
                      borderRadius="50%"
                      width="40px"
                      height="40px"
                      paddingHorizontal={5}
                      paddingVertical={5}
                      alignSelf="flex-start"
                    >
                      <Text
                        type={TextTypes.BulletLi}
                        color={colorPalette.secondary as Colors}
                      >
                        {index < 9 ? `0${index + 1}` : index + 1}
                      </Text>
                    </Container>
                  </div>

                  <Text
                    align="left"
                    type={TextTypes.BulletLiContent}
                    color={colorPalette.secondary as Colors}
                  >
                    {listItem}
                  </Text>
                </Container>
              ))}
            </Container>
          </Container>
        );
      })}
    </Container>
  );
};
