import React from "react";
import styles from "./styles.module.scss";
import { ImageProps } from "./types";
import { SrcPrefixUrl } from "../../constants/default";

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  className,
  style,
  width,
  height,
  noPrefix,
}) => {
  return (
    <img
      src={`${noPrefix ? "" : SrcPrefixUrl}${src}`}
      alt={alt}
      className={`${styles.image} ${className}`}
      style={{ ...style, width, height }}
    />
  );
};

export default Image;
