import { Colors } from "../../constants/default";
import { ContainerProps } from "./types";
import styles from "./styles.module.scss";
import useIsMobile from "../../hooks/useMobile";

export const Container = ({
  BG = Colors.None,
  children,
  paddingHorizontal = 0,
  paddingVertical = 0,
  maxWidth,
  alignItems,
  flexDirection = "column",
  gap,
  paddingBottom,
  paddingTop,
  paddingLeft,
  paddingRight,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  position,
  height,
  minHeight,
  alignSelf,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  width,
  flexGrow,
  flexShrink,
  flexBasis,
  flexWrap,
  justifyContent = "center",
  boxShadow,
  minWidth,
  display,
  overflow,
  ref,
  className,
  mobileStyles,
  margin,
  id,
  zIndex,
  boxShadowColor = "rgb(217 221 233)",
  border,
}: ContainerProps) => {
  const isMobile = useIsMobile();
  const mobileStylesProps =
    mobileStyles && isMobile
      ? {
          ...(mobileStyles.BG ? { backgroundColor: mobileStyles.BG } : {}),
          ...(mobileStyles.paddingHorizontal ||
          mobileStyles.paddingLeft ||
          mobileStyles.paddingRight
            ? {
                paddingLeft:
                  mobileStyles.paddingHorizontal || mobileStyles.paddingLeft,
                paddingRight:
                  mobileStyles.paddingHorizontal || mobileStyles.paddingRight,
              }
            : {}),
          ...(mobileStyles.paddingVertical ||
          mobileStyles.paddingBottom ||
          mobileStyles.paddingTop
            ? {
                paddingTop:
                  mobileStyles.paddingVertical || mobileStyles.paddingTop,
                paddingBottom:
                  mobileStyles.paddingVertical || mobileStyles.paddingBottom,
              }
            : {}),

          ...(mobileStyles.marginTop
            ? { marginTop: `${mobileStyles.marginTop}px` }
            : {}),
          ...(mobileStyles.marginBottom
            ? { marginBottom: `${mobileStyles.marginBottom}px` }
            : {}),
          ...(mobileStyles.marginLeft
            ? { marginLeft: `${mobileStyles.marginLeft}px` }
            : {}),
          ...(mobileStyles.marginRight
            ? { marginRight: `${mobileStyles.marginRight}px` }
            : {}),
          maxWidth: mobileStyles.maxWidth
            ? typeof mobileStyles.maxWidth === "string"
              ? mobileStyles.maxWidth
              : `${mobileStyles.maxWidth}px`
            : "100%",
          ...(mobileStyles.alignItems
            ? { alignItems: mobileStyles.alignItems }
            : {}),
          ...(mobileStyles.flexDirection
            ? { flexDirection: mobileStyles.flexDirection }
            : {}),
          ...(mobileStyles.justifyContent
            ? { justifyContent: mobileStyles.justifyContent }
            : {}),
          gap: mobileStyles.gap && `${mobileStyles.gap}px`,
          ...(mobileStyles.position ? { position: mobileStyles.position } : {}),
          height: mobileStyles.height,
          minHeight: mobileStyles.minHeight,
          ...(mobileStyles.alignSelf
            ? { alignSelf: mobileStyles.alignSelf }
            : {}),
          ...(mobileStyles.borderRadius
            ? { borderRadius: mobileStyles.borderRadius }
            : {}),
          ...(mobileStyles.borderTopLeftRadius
            ? { borderTopLeftRadius: mobileStyles.borderTopLeftRadius }
            : {}),
          ...(mobileStyles.borderTopRightRadius
            ? { borderTopRightRadius: mobileStyles.borderTopRightRadius }
            : {}),
          ...(mobileStyles.borderBottomLeftRadius
            ? { borderBottomLeftRadius: mobileStyles.borderTopLeftRadius }
            : {}),
          ...(mobileStyles.borderBottomRightRadius
            ? { borderBottomRightRadius: mobileStyles.borderBottomRightRadius }
            : {}),
          width: mobileStyles.width,
          ...(mobileStyles.flexGrow ? { flexGrow: mobileStyles.flexGrow } : {}),
          ...(mobileStyles.flexShrink
            ? { flexShrink: mobileStyles.flexShrink }
            : {}),
          ...(mobileStyles.flexBasis
            ? { flexBasis: mobileStyles.flexBasis }
            : {}),
          ...(mobileStyles.boxShadow
            ? {
                boxShadow: `0px ${-mobileStyles.boxShadow}px ${
                  mobileStyles.boxShadow / 2
                }px ${boxShadowColor}`,
              }
            : {}),
          ...(mobileStyles.minWidth ? { minWidth: mobileStyles.minWidth } : {}),
          ...(mobileStyles.display ? { display: mobileStyles.display } : {}),
          ...(mobileStyles.overflow ? { overflow: mobileStyles.overflow } : {}),
          ...(mobileStyles.flexWrap ? { flexWrap: mobileStyles.flexWrap } : {}),
          ...(mobileStyles.margin ? { margin: mobileStyles.margin } : {}),
        }
      : {};
  return (
    <div
      id={id}
      ref={ref}
      className={`${styles.layout}${className ? ` ${className}` : ""}`}
      style={{
        zIndex: zIndex,
        backgroundColor: BG,
        ...(paddingHorizontal || paddingLeft || paddingRight
          ? {
              paddingLeft: paddingHorizontal || paddingLeft,
              paddingRight: paddingHorizontal || paddingRight,
            }
          : {}),
        ...(paddingVertical || paddingBottom || paddingTop
          ? {
              paddingTop: paddingVertical || paddingTop,
              paddingBottom: paddingVertical || paddingBottom,
            }
          : {}),

        ...(marginTop ? { marginTop: `${marginTop}px` } : {}),
        ...(marginBottom ? { marginBottom: `${marginBottom}px` } : {}),
        ...(marginLeft ? { marginLeft: `${marginLeft}px` } : {}),
        ...(marginRight ? { marginRight: `${marginRight}px` } : {}),
        maxWidth: maxWidth
          ? typeof maxWidth === "string"
            ? maxWidth
            : `${maxWidth}px`
          : "100%",
        alignItems: alignItems,
        flexDirection: flexDirection,
        ...(justifyContent ? { justifyContent } : {}),
        gap: gap && `${gap}px`,
        position,
        height,
        minHeight,
        alignSelf,
        ...(borderRadius ? { borderRadius } : {}),
        ...(borderTopLeftRadius ? { borderTopLeftRadius } : {}),
        ...(borderTopRightRadius ? { borderTopRightRadius } : {}),
        ...(borderBottomLeftRadius ? { borderBottomLeftRadius } : {}),
        ...(borderBottomRightRadius ? { borderBottomRightRadius } : {}),
        width,
        ...(flexGrow ? { flexGrow } : {}),
        ...(flexShrink ? { flexShrink } : {}),
        ...(flexBasis ? { flexBasis } : {}),
        ...(flexWrap ? { flexWrap } : {}),
        ...(boxShadow
          ? {
              boxShadow: `0px ${-boxShadow}px ${
                boxShadow / 2
              }px ${boxShadowColor}`,
            }
          : {}),
        ...(minWidth ? { minWidth } : {}),
        ...(display ? { display } : {}),
        ...(overflow ? { overflow } : {}),
        ...(margin ? { margin } : {}),
        ...(border ? { border: `1px solid white` } : {}),
        ...mobileStylesProps,
      }}
    >
      {children}
    </div>
  );
};
