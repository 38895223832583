export enum UserFormsMapKeys {
  JoinTheTeam = "join-the-team",
  Feedback = "feedback",
  Hire = "hire",
  HireWeb = "hire-web",
  HireMobile = "hire-mobile",
  HireUI_UX = "hire-ui-ux",
  HireIntegrations = "hire-integrations",
}

export const UserFormsMap = {
  en: {
    [UserFormsMapKeys.JoinTheTeam]: {
      title: "Join The Team",
      description: "Are you finished? Would you like to revisit?",
      subTitle: "See you on the other side soon!",
      formId: "join-the-team",
      link: "https://app.youform.com/forms/xvomnlvc",
    },
    [UserFormsMapKeys.Feedback]: {
      title: "User Feedback",
      description: "Are you finished? Would you like to revisit?",
      subTitle: "Your Insights Drive Our Excellence!",
      formId: "feedback",
      link: "https://app.youform.com/forms/p1bhhmgq",
    },
    [UserFormsMapKeys.Hire]: {
      title: "Hire Navvan",
      description: "Are you finished? Would you like to revisit?",
      subTitle:
        "Navvan provides a tailored suite of services that are fast, accurate, complete and affordable to users worldwide",
      formId: "hire",
      link: "https://app.youform.com/forms/scxhxqwu",
    },
    [UserFormsMapKeys.HireWeb]: {
      title: "Hire Navvan",
      description: "Are you finished? Would you like to revisit?",
      subTitle:
        "Navvan provides a tailored suite of services that are fast, accurate, complete and affordable to users worldwide",
      formId: "hire-web",
      link: "https://app.youform.com/forms/5lhggd2t",
    },
    [UserFormsMapKeys.HireMobile]: {
      title: "Hire Navvan",
      description: "Are you finished? Would you like to revisit?",
      subTitle:
        "Navvan provides a tailored suite of services that are fast, accurate, complete and affordable to users worldwide",
      formId: "hire-mobile",
      link: "https://app.youform.com/forms/eqnxdli4",
    },
    [UserFormsMapKeys.HireUI_UX]: {
      title: "Hire Navvan",
      description: "Are you finished? Would you like to revisit?",
      subTitle:
        "Navvan provides a tailored suite of services that are fast, accurate, complete and affordable to users worldwide",
      formId: "hire-ui-ux",
      link: "https://app.youform.com/forms/w0wtyoki",
    },
    [UserFormsMapKeys.HireIntegrations]: {
      title: "Hire Navvan",
      description: "Are you finished? Would you like to revisit?",
      subTitle:
        "Navvan provides a tailored suite of services that are fast, accurate, complete and affordable to users worldwide",
      formId: "hire-integrations",
      link: "https://app.youform.com/forms/5igwl3no",
    },
    button: "Navvan Home",
  },
  es: {
    "join-the-team": {
      title: "Únete al equipo",
      description: "¿Has terminado? ¿Te gustaría revisarlo?",
      subTitle: "¡Nos vemos al otro lado pronto!",
      formId: "join-the-team",
      link: "https://app.youform.com/forms/xvomnlvc",
    },
    feedback: {
      title: "Comentarios de los usuarios",
      description: "¿Has terminado? ¿Te gustaría revisarlo?",
      subTitle: "¡Tus ideas impulsan nuestra excelencia!",
      formId: "feedback",
      link: "https://app.youform.com/forms/p1bhhmgq",
    },
    hire: {
      title: "Contrata a Navvan",
      description: "¿Has terminado? ¿Te gustaría revisarlo?",
      subTitle:
        "Navvan ofrece un conjunto de servicios adaptados que son rápidos, precisos, completos y asequibles para usuarios en todo el mundo",
      formId: "hire",
      link: "https://app.youform.com/forms/scxhxqwu",
    },
    "hire-web": {
      title: "Contrata a Navvan",
      description: "¿Has terminado? ¿Te gustaría revisarlo?",
      subTitle:
        "Navvan ofrece un conjunto de servicios adaptados que son rápidos, precisos, completos y asequibles para usuarios en todo el mundo",
      formId: "hire-web",
      link: "https://app.youform.com/forms/5lhggd2t",
    },
    "hire-mobile": {
      title: "Contrata a Navvan",
      description: "¿Has terminado? ¿Te gustaría revisarlo?",
      subTitle:
        "Navvan ofrece un conjunto de servicios adaptados que son rápidos, precisos, completos y asequibles para usuarios en todo el mundo",
      formId: "hire-mobile",
      link: "https://app.youform.com/forms/eqnxdli4",
    },
    "hire-ui-ux": {
      title: "Contrata a Navvan",
      description: "¿Has terminado? ¿Te gustaría revisarlo?",
      subTitle:
        "Navvan ofrece un conjunto de servicios adaptados que son rápidos, precisos, completos y asequibles para usuarios en todo el mundo",
      formId: "hire-ui-ux",
      link: "https://app.youform.com/forms/w0wtyoki",
    },
    "hire-integrations": {
      title: "Contrata a Navvan",
      description: "¿Has terminado? ¿Te gustaría revisarlo?",
      subTitle:
        "Navvan ofrece un conjunto de servicios adaptados que son rápidos, precisos, completos y asequibles para usuarios en todo el mundo",
      formId: "hire-integrations",
      link: "https://app.youform.com/forms/5igwl3no",
    },
    button: "Inicio de Navvan",
  },
  ar: {
    "join-the-team": {
      title: "انضم إلى الفريق",
      description: "هل انتهيت؟ هل ترغب في إعادة النظر؟",
      subTitle: "أراك في الجانب الآخر قريبًا!",
      formId: "join-the-team",
      link: "https://app.youform.com/forms/xvomnlvc",
    },
    feedback: {
      title: "ملاحظات المستخدمين",
      description: "هل انتهيت؟ هل ترغب في إعادة النظر؟",
      subTitle: "رؤاك تدفع تميزنا!",
      formId: "feedback",
      link: "https://app.youform.com/forms/p1bhhmgq",
    },
    hire: {
      title: "توظيف نافان",
      description: "هل انتهيت؟ هل ترغب في إعادة النظر؟",
      subTitle:
        "تقدم نافان مجموعة خدمات مصممة خصيصًا تكون سريعة ودقيقة وكاملة وبأسعار معقولة للمستخدمين في جميع أنحاء العالم",
      formId: "hire",
      link: "https://app.youform.com/forms/scxhxqwu",
    },
    "hire-web": {
      title: "توظيف نافان",
      description: "هل انتهيت؟ هل ترغب في إعادة النظر؟",
      subTitle:
        "تقدم نافان مجموعة خدمات مصممة خصيصًا تكون سريعة ودقيقة وكاملة وبأسعار معقولة للمستخدمين في جميع أنحاء العالم",
      formId: "hire-web",
      link: "https://app.youform.com/forms/5lhggd2t",
    },
    "hire-mobile": {
      title: "توظيف نافان",
      description: "هل انتهيت؟ هل ترغب في إعادة النظر؟",
      subTitle:
        "تقدم نافان مجموعة خدمات مصممة خصيصًا تكون سريعة ودقيقة وكاملة وبأسعار معقولة للمستخدمين في جميع أنحاء العالم",
      formId: "hire-mobile",
      link: "https://app.youform.com/forms/eqnxdli4",
    },
    "hire-ui-ux": {
      title: "توظيف نافان",
      description: "هل انتهيت؟ هل ترغب في إعادة النظر؟",
      subTitle:
        "تقدم نافان مجموعة خدمات مصممة خصيصًا تكون سريعة ودقيقة وكاملة وبأسعار معقولة للمستخدمين في جميع أنحاء العالم",
      formId: "hire-ui-ux",
      link: "https://app.youform.com/forms/w0wtyoki",
    },
    "hire-integrations": {
      title: "توظيف نافان",
      description: "هل انتهيت؟ هل ترغب في إعادة النظر؟",
      subTitle:
        "تقدم نافان مجموعة خدمات مصممة خصيصًا تكون سريعة ودقيقة وكاملة وبأسعار معقولة للمستخدمين في جميع أنحاء العالم",
      formId: "hire-integrations",
      link: "https://app.youform.com/forms/5igwl3no",
    },
    button: "الصفحة الرئيسية لنافان",
  },
  de: {
    "join-the-team": {
      title: "Tritt dem Team bei",
      description: "Bist du fertig? Möchtest du es noch einmal durchsehen?",
      subTitle: "Wir sehen uns bald auf der anderen Seite!",
      formId: "join-the-team",
      link: "https://app.youform.com/forms/xvomnlvc",
    },
    feedback: {
      title: "Nutzerfeedback",
      description: "Bist du fertig? Möchtest du es noch einmal durchsehen?",
      subTitle: "Deine Einsichten treiben unsere Exzellenz voran!",
      formId: "feedback",
      link: "https://app.youform.com/forms/p1bhhmgq",
    },
    hire: {
      title: "Stelle Navvan ein",
      description: "Bist du fertig? Möchtest du es noch einmal durchsehen?",
      subTitle:
        "Navvan bietet ein maßgeschneidertes Dienstleistungsangebot, das schnell, genau, umfassend und für Nutzer weltweit erschwinglich ist",
      formId: "hire",
      link: "https://app.youform.com/forms/scxhxqwu",
    },
    "hire-web": {
      title: "Stelle Navvan ein",
      description: "Bist du fertig? Möchtest du es noch einmal durchsehen?",
      subTitle:
        "Navvan bietet ein maßgeschneidertes Dienstleistungsangebot, das schnell, genau, umfassend und für Nutzer weltweit erschwinglich ist",
      formId: "hire-web",
      link: "https://app.youform.com/forms/5lhggd2t",
    },
    "hire-mobile": {
      title: "Stelle Navvan ein",
      description: "Bist du fertig? Möchtest du es noch einmal durchsehen?",
      subTitle:
        "Navvan bietet ein maßgeschneidertes Dienstleistungsangebot, das schnell, genau, umfassend und für Nutzer weltweit erschwinglich ist",
      formId: "hire-mobile",
      link: "https://app.youform.com/forms/eqnxdli4",
    },
    "hire-ui-ux": {
      title: "Stelle Navvan ein",
      description: "Bist du fertig? Möchtest du es noch einmal durchsehen?",
      subTitle:
        "Navvan bietet ein maßgeschneidertes Dienstleistungsangebot, das schnell, genau, umfassend und für Nutzer weltweit erschwinglich ist",
      formId: "hire-ui-ux",
      link: "https://app.youform.com/forms/w0wtyoki",
    },
    "hire-integrations": {
      title: "Stelle Navvan ein",
      description: "Bist du fertig? Möchtest du es noch einmal durchsehen?",
      subTitle:
        "Navvan bietet ein maßgeschneidertes Dienstleistungsangebot, das schnell, genau, umfassend und für Nutzer weltweit erschwinglich ist",
      formId: "hire-integrations",
      link: "https://app.youform.com/forms/5igwl3no",
    },
    button: "Navvan-Startseite",
  },
  pt: {
    "join-the-team": {
      title: "Junte-se à equipe",
      description: "Você terminou? Gostaria de revisitar?",
      subTitle: "Até logo do outro lado!",
      formId: "join-the-team",
      link: "https://app.youform.com/forms/xvomnlvc",
    },
    feedback: {
      title: "Feedback do Usuário",
      description: "Você terminou? Gostaria de revisitar?",
      subTitle: "Seus insights impulsionam nossa excelência!",
      formId: "feedback",
      link: "https://app.youform.com/forms/p1bhhmgq",
    },
    hire: {
      title: "Contrate a Navvan",
      description: "Você terminou? Gostaria de revisitar?",
      subTitle:
        "A Navvan oferece um conjunto de serviços personalizados que são rápidos, precisos, completos e acessíveis para usuários em todo o mundo",
      formId: "hire",
      link: "https://app.youform.com/forms/scxhxqwu",
    },
    "hire-web": {
      title: "Contrate a Navvan",
      description: "Você terminou? Gostaria de revisitar?",
      subTitle:
        "A Navvan oferece um conjunto de serviços personalizados que são rápidos, precisos, completos e acessíveis para usuários em todo o mundo",
      formId: "hire-web",
      link: "https://app.youform.com/forms/5lhggd2t",
    },
    "hire-mobile": {
      title: "Contrate a Navvan",
      description: "Você terminou? Gostaria de revisitar?",
      subTitle:
        "A Navvan oferece um conjunto de serviços personalizados que são rápidos, precisos, completos e acessíveis para usuários em todo o mundo",
      formId: "hire-mobile",
      link: "https://app.youform.com/forms/eqnxdli4",
    },
    "hire-ui-ux": {
      title: "Contrate a Navvan",
      description: "Você terminou? Gostaria de revisitar?",
      subTitle:
        "A Navvan oferece um conjunto de serviços personalizados que são rápidos, precisos, completos e acessíveis para usuários em todo o mundo",
      formId: "hire-ui-ux",
      link: "https://app.youform.com/forms/w0wtyoki",
    },
    "hire-integrations": {
      title: "Contrate a Navvan",
      description: "Você terminou? Gostaria de revisitar?",
      subTitle:
        "A Navvan oferece um conjunto de serviços personalizados que são rápidos, precisos, completos e acessíveis para usuários em todo o mundo",
      formId: "hire-integrations",
      link: "https://app.youform.com/forms/5igwl3no",
    },
    button: "Início da Navvan",
  },
  ru: {
    "join-the-team": {
      title: "Присоединяйтесь к команде",
      description: "Вы закончили? Хотите пересмотреть?",
      subTitle: "Увидимся с вами на другой стороне скоро!",
      formId: "join-the-team",
      link: "https://app.youform.com/forms/xvomnlvc",
    },
    feedback: {
      title: "Обратная связь пользователей",
      description: "Вы закончили? Хотите пересмотреть?",
      subTitle: "Ваши идеи движут нашим совершенством!",
      formId: "feedback",
      link: "https://app.youform.com/forms/p1bhhmgq",
    },
    hire: {
      title: "Нанять Navvan",
      description: "Вы закончили? Хотите пересмотреть?",
      subTitle:
        "Navvan предлагает набор услуг, который является быстрым, точным, полным и доступным для пользователей по всему миру",
      formId: "hire",
      link: "https://app.youform.com/forms/scxhxqwu",
    },
    "hire-web": {
      title: "Нанять Navvan",
      description: "Вы закончили? Хотите пересмотреть?",
      subTitle:
        "Navvan предлагает набор услуг, который является быстрым, точным, полным и доступным для пользователей по всему миру",
      formId: "hire-web",
      link: "https://app.youform.com/forms/5lhggd2t",
    },
    "hire-mobile": {
      title: "Нанять Navvan",
      description: "Вы закончили? Хотите пересмотреть?",
      subTitle:
        "Navvan предлагает набор услуг, который является быстрым, точным, полным и доступным для пользователей по всему миру",
      formId: "hire-mobile",
      link: "https://app.youform.com/forms/eqnxdli4",
    },
    "hire-ui-ux": {
      title: "Нанять Navvan",
      description: "Вы закончили? Хотите пересмотреть?",
      subTitle:
        "Navvan предлагает набор услуг, который является быстрым, точным, полным и доступным для пользователей по всему миру",
      formId: "hire-ui-ux",
      link: "https://app.youform.com/forms/w0wtyoki",
    },
    "hire-integrations": {
      title: "Нанять Navvan",
      description: "Вы закончили? Хотите пересмотреть?",
      subTitle:
        "Navvan предлагает набор услуг, который является быстрым, точным, полным и доступным для пользователей по всему миру",
      formId: "hire-integrations",
      link: "https://app.youform.com/forms/5igwl3no",
    },
    button: "Главная Navvan",
  },

  fr: {
    "join-the-team": {
      title: "Rejoignez l'équipe",
      description: "Avez-vous terminé ? Souhaitez-vous le revoir ?",
      subTitle: "À bientôt de l'autre côté !",
      formId: "join-the-team",
      link: "https://app.youform.com/forms/xvomnlvc",
    },
    feedback: {
      title: "Retour d'expérience utilisateur",
      description: "Avez-vous terminé ? Souhaitez-vous le revoir ?",
      subTitle: "Vos idées guident notre excellence !",
      formId: "feedback",
      link: "https://app.youform.com/forms/p1bhhmgq",
    },
    hire: {
      title: "Embaucher Navvan",
      description: "Avez-vous terminé ? Souhaitez-vous le revoir ?",
      subTitle:
        "Navvan propose une suite de services sur mesure qui sont rapides, précis, complets et abordables pour les utilisateurs du monde entier",
      formId: "hire",
      link: "https://app.youform.com/forms/scxhxqwu",
    },
    "hire-web": {
      title: "Embaucher Navvan",
      description: "Avez-vous terminé ? Souhaitez-vous le revoir ?",
      subTitle:
        "Navvan propose une suite de services sur mesure qui sont rapides, précis, complets et abordables pour les utilisateurs du monde entier",
      formId: "hire-web",
      link: "https://app.youform.com/forms/5lhggd2t",
    },
    "hire-mobile": {
      title: "Embaucher Navvan",
      description: "Avez-vous terminé ? Souhaitez-vous le revoir ?",
      subTitle:
        "Navvan propose une suite de services sur mesure qui sont rapides, précis, complets et abordables pour les utilisateurs du monde entier",
      formId: "hire-mobile",
      link: "https://app.youform.com/forms/eqnxdli4",
    },
    "hire-ui-ux": {
      title: "Embaucher Navvan",
      description: "Avez-vous terminé ? Souhaitez-vous le revoir ?",
      subTitle:
        "Navvan propose une suite de services sur mesure qui sont rapides, précis, complets et abordables pour les utilisateurs du monde entier",
      formId: "hire-ui-ux",
      link: "https://app.youform.com/forms/w0wtyoki",
    },
    "hire-integrations": {
      title: "Embaucher Navvan",
      description: "Avez-vous terminé ? Souhaitez-vous le revoir ?",
      subTitle:
        "Navvan propose une suite de services sur mesure qui sont rapides, précis, complets et abordables pour les utilisateurs du monde entier",
      formId: "hire-integrations",
      link: "https://app.youform.com/forms/5igwl3no",
    },
    button: "Accueil de Navvan",
  },
};
