import React from "react";
import { Container } from "../Container";
import Text from "../Text";
import { Colors, FontFamily, TextTypes } from "../../constants/default";
import { Spacer } from "../Spacer";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import LottieAnimation from "../Lottie";
import Trash from "../../assets/lottie/trash.json";
import useIsMobile from "../../hooks/useMobile";
import { useAppContext } from "../../context/AppContext";
import { Messages } from "./constants";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const align = isMobile ? "center" : "left";
  const { selectedLanguage } = useAppContext();
  return (
    <Container
      flexDirection="row"
      BG={Colors.PrimaryWine}
      height="100vh"
      width="100vw"
      justifyContent="space-between"
      mobileStyles={{ flexDirection: "column" }}
    >
      <Container
        width="50%"
        mobileStyles={{ width: "90%" }}
        paddingHorizontal={isMobile ? 10 : 100}
      >
        <Text
          type={TextTypes.BigHeader}
          fontFamily={FontFamily.AttenNew}
          align={align}
        >
          404
        </Text>

        <Text
          type={TextTypes.SubTitleSpaced}
          fontFamily={FontFamily.AttenNew}
          align={align}
        >
          {(Messages as any)[selectedLanguage as any]["page-not-found"]}
        </Text>
        <Spacer height={40} />
        <Text
          type={TextTypes.SubTitle}
          fontFamily={FontFamily.AttenNew}
          align={align}
        >
          {(Messages as any)[selectedLanguage as any]["error-message"]}
        </Text>
        <Spacer height={40} />
        <Container alignSelf={isMobile ? "center" : "flex-start"}>
          <Button
            title={(Messages as any)[selectedLanguage as any]["button"]}
            onClick={() => navigate("/home")}
            href=""
          />
        </Container>
      </Container>

      <Container>
        <LottieAnimation animationData={Trash} />
      </Container>
    </Container>
  );
};

export default NotFound;
