import { useState } from "react";
import { DefaultAppMappingType } from "../constants/appMapping";
import en from "../services/translations/content/en.json";
import ar from "../services/translations/content/ar.json";
import de from "../services/translations/content/de.json";
import es from "../services/translations/content/es.json";
import fr from "../services/translations/content/fr.json";
import pt from "../services/translations/content/pt.json";
import ru from "../services/translations/content/ru.json";

const LanguageMap = {
  en,
  es,
  ar,
  de,
  fr,
  pt,
  ru,
};

// Utility function to get a cache key based on the content type list
// const getCacheKey = (appMapping: DefaultAppMappingType) => {
//   return `dataCache_${appMapping.ContentTypeList.map(
//     (content) => content.contentType
//   ).join("_")}`;
// };

/**
 * Custom hook for fetching and managing data.
 *
 * @param {Object} options - The options for the hook.
 * @param {DefaultAppMappingType} options.appMapping - The app mapping object.
 * @returns {Object} - An object containing the loading state and the data list.
 */
export const useGetData = ({
  // appMapping,
  selectedLanguage,
}: {
  appMapping: DefaultAppMappingType;
  selectedLanguage: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, $setIsLoading] = useState(true);
  // const [dataList, setDataList] = useState<any[]>([]);
  // const isMounted = useRef(false); // Initializing as false, since this is the first mount

  // useEffect(() => {
  //   const fetchAndSetData = async () => {
  //     try {
  //       // Fetch data from the API
  //       const fetchedDataList = await Promise.all(
  //         appMapping.ContentTypeList.map((content) =>
  //           getData({
  //             content_type: content.contentType,
  //             select: Fields,
  //           }).catch((e) => console.log(e))
  //         )
  //       );

  //       // Store the fetched data in localStorage
  //       const cacheKey = getCacheKey(appMapping);
  //       localStorage.setItem(
  //         cacheKey,
  //         JSON.stringify(fetchedDataList.map((data) => data[0]))
  //       );

  //       // Update state with the fetched data
  //       setDataList(fetchedDataList.map((data) => data[0]));
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   const loadCachedData = () => {
  //     const cacheKey = getCacheKey(appMapping);
  //     const cachedData = localStorage.getItem(cacheKey);
  //     if (cachedData) {
  //       setDataList(JSON.parse(cachedData));
  //       return true;
  //     }
  //     return false;
  //   };

  //   // Check for cached data and fetch in the background if needed
  //   if (!isMounted.current) {
  //     setIsLoading(true);

  //     if (loadCachedData()) {
  //       // Ensure we set loading to false even if we load from cache
  //       setIsLoading(false);
  //     }
  //     fetchAndSetData();

  //     isMounted.current = true;
  //   }
  // }, [appMapping]);

  return {
    isLoading,
    dataList: LanguageMap[selectedLanguage as keyof typeof LanguageMap],
  };
};
