import { Nesting_Level } from "../constants/contentful/other";
import { client } from "./client";
import { getSanitizedData } from "../utils/sanitizeData";

export const getData = async ({
  content_type,
  select,
}: {
  content_type: string;
  select: any;
}) => {
  try {
    const result = await client.getEntries({
      content_type,
      select,
      include: Nesting_Level,
    });
    const sanitizedResult = getSanitizedData({
      data: result.items,
      content_type,
    });
    return sanitizedResult;
  } catch (e) {
    console.log("error", e);
  }
};
