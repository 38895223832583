import { Container } from "../../components/Container";
import Image from "../../components/Image";
import { ScrollerTop } from "../../components/ScrollerTop";
import { Colors } from "../../constants/default";
import useIsMobile from "../../hooks/useMobile";
import { FaqPageProps as OwnerProps } from "../faq/types";

export const OwnerNotes = ({ data, scrollToRef }: OwnerProps) => {
  const { footerLogo } = data;
  const isMobile = useIsMobile();
  return (
    <Container
      id={"ownerNotes"}
      BG={Colors.PrimaryGray}
      width="100%"
      alignItems="center"
      paddingTop={30}
      paddingBottom={30}
      position="relative"
    >
      <Image
        src={footerLogo.file.url}
        alt={footerLogo.title}
        width={isMobile ? "95%" : "70%"}
      />
      <ScrollerTop scrollToRef={scrollToRef} id="landingHome" rightAlign />
    </Container>
  );
};
