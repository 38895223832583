import { useNavigate } from "react-router-dom";
import { Colors, TextTypes } from "../../constants/default";
import Text from "../Text";
import StyledLink from "./LinkStyled";
import { ILinkProps } from "./types";

const Link = ({
  title,
  href,
  textType = TextTypes.Link,
  color = Colors.PrimaryCharcoal,
  navigatorFn,
  isCurrentPage,
  isFormRoute,
  formRouteName,
}: ILinkProps) => {
  const routeNavigate = useNavigate();
  return (
    <StyledLink
      onClick={() => {
        if (href) {
          if (navigatorFn && isCurrentPage) {
            navigatorFn(href);
          } else if (isFormRoute) {
            routeNavigate(`/user-form/${formRouteName}`);
          } else {
            window.open(`${href}`, "_blank");
          }
        }
      }}
      color={color}
    >
      <Text type={textType} color={color}>
        {title}
      </Text>
    </StyledLink>
  );
};

export default Link;
