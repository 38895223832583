import { Container } from "../../components/Container";
import { ScrollerTop } from "../../components/ScrollerTop";
import { HorizontalSeparator } from "../../components/Separator";
import { Spacer } from "../../components/Spacer";
import Text from "../../components/Text";
import { Colors, FontFamily, TextTypes } from "../../constants/default";
import { ToggleItem } from "./components/ToggleItem";
import { FaqPageProps } from "./types";

export const FaqPage = ({ data, scrollToRef }: FaqPageProps) => {
  const { header, contentList, toggleClose, toggleOpen } = data;

  return (
    <Container BG={Colors.PrimaryBeige} id={data.sectionId} position="relative">
      <Container
        paddingHorizontal={350}
        paddingTop={128}
        paddingBottom={123}
        mobileStyles={{
          paddingHorizontal: 20,
          paddingBottom: 61.5,
          paddingTop: 64,
        }}
      >
        <Text
          type={TextTypes.RatingFooterTitle}
          color={Colors.PrimartCharcoal50}
        >
          {header.titlePrefix}
        </Text>
        <Spacer height={24} />
        <Text type={TextTypes.Header} fontFamily={FontFamily.AttenNew}>
          {header.title}
        </Text>
      </Container>
      <Container
        width="60%"
        gap={32}
        marginBottom={40}
        mobileStyles={{ width: "80%" }}
      >
        {contentList.map(({ title, subTitle }, index) => {
          return (
            <>
              <HorizontalSeparator thickness={1} color={Colors.Separator} />
              <ToggleItem
                key={index}
                title={title}
                subTitle={subTitle}
                toggleClose={toggleClose}
                toggleOpen={toggleOpen}
              />
            </>
          );
        })}
      </Container>
      <ScrollerTop
        id="landingHome"
        scrollToRef={scrollToRef}
        rightAlign
      ></ScrollerTop>
    </Container>
  );
};
