import { Container } from "../../../components/Container";
import { Logo } from "../../../components/Logo";
import { HorizontalSeparator } from "../../../components/Separator";
import Text from "../../../components/Text";
import { Colors, FontFamily, TextTypes } from "../../../constants/default";
import { ScrollCard } from "../types";

interface CardContentProps {
  header: ScrollCard["header"];
  content: ScrollCard["content"];
  colorPalette: ScrollCard["colorPalette"];
}

export const CardContent = ({
  header,
  content,
  colorPalette,
}: CardContentProps) => {
  return (
    <Container gap={78} alignSelf="flex-start" alignItems="left">
      {/* Container for the header */}
      <Container
        gap={22}
        flexDirection="row"
        alignItems="center"
        alignSelf="flex-start"
        mobileStyles={{
          gap: 11,
          justifyContent: "center",
        }}
      >
        {/* Logo component */}
        <Logo url={header.logo.file.url} width={67} height={67}></Logo>
        {/* Text component for the header title */}
        <Text
          type={TextTypes.HeaderMedium}
          fontFamily={FontFamily.AttenNew}
          color={colorPalette.secondary as Colors}
        >
          {header.title}
        </Text>
      </Container>
      {/* Container for the content */}
      <Container gap={37}>
        <Container gap={22} alignSelf="flex-start">
          {/* Text component for the content title */}
          <Text
            type={TextTypes.HeaderSmall}
            fontFamily={FontFamily.AttenNew}
            align="left"
            color={Colors.CardContent}
          >
            {content.title}
          </Text>
          {/* Horizontal separator */}
          <HorizontalSeparator
            color={colorPalette.secondary as Colors}
          ></HorizontalSeparator>
          {/* Text component for the content subtitle */}
          <Text
            type={TextTypes.SubTitleMedium}
            color={Colors.CardContent}
            align="left"
          >
            {content.subTitle}
          </Text>
        </Container>

        {/* Loop through the list details */}
        {content.listDetails.map((detail, index) => (
          <Container
            gap={24}
            flexDirection="row"
            alignSelf="flex-start"
            alignItems="center"
            maxWidth={"80%"}
          >
            <Container BG="transparent">
              <Container
                BG={Colors.PrimaryWhite}
                borderRadius="50%"
                width="35px"
                height="35px"
                paddingHorizontal={5}
                paddingVertical={5}
              >
                {/* Text component for the bullet point */}
                <Text
                  type={TextTypes.BulletLi}
                  color={colorPalette.secondary as Colors}
                >
                  {index < 9 ? `0${index + 1}` : index + 1}
                </Text>
              </Container>
            </Container>
            {/* Text component for the detail */}
            <Text
              color={Colors.CardContent}
              type={TextTypes.BulletLiContent}
              align="left"
            >
              {detail}
            </Text>
          </Container>
        ))}
      </Container>
    </Container>
  );
};
