import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ErrorBoundary from "../../components/common/ErrorBoundary";
import NotFound from "../../components/common/NotFound";
import { Home } from "../../pages/home";
import { UserForms } from "../UserForms";
import useAnalytics from "../../hooks/useAnalytics";
import useScrollTracking from "../../hooks/useScrollTracking";
import Blogs from "../../pages/blogs/Blogs";

const AppRouter: React.FC = () => {
  useAnalytics();
  useScrollTracking();
  return (
    <ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
      <Routes>
        <Route path="*" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/user-form/:formId" element={<UserForms />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRouter;
