export const Spacer = ({ height }: { height: number }) => {
  return (
    <div
      style={{
        height: height,
        width: "100%",
      }}
    ></div>
  );
};
