import { Container } from "../../components/Container";
import Image from "../../components/Image";
import { Spacer } from "../../components/Spacer";
import Text from "../../components/Text";
import { Colors, FontFamily, TextTypes } from "../../constants/default";
import { IntegrationPageProps } from "./types";
import styles from "./styles.module.scss";
import { ScrollerTop } from "../../components/ScrollerTop";

export const Integrations = ({ data, scrollToRef }: IntegrationPageProps) => {
  const { header, logo, sectionId } = data;
  return (
    // Outer container with background color and padding
    <Container
      BG={Colors.PrimaryBeige}
      paddingBottom={179}
      mobileStyles={{
        paddingBottom: 90,
      }}
      id={sectionId}
      position="relative"
    >
      <Container
        // Inner container with horizontal padding and top padding
        paddingHorizontal={350}
        paddingTop={128}
        mobileStyles={{
          paddingHorizontal: 20,
          paddingTop: 64,
        }}
      >
        {/* Text component displaying the title prefix */}
        <Text
          type={TextTypes.RatingFooterTitle}
          color={Colors.PrimartCharcoal50}
        >
          {header.titlePrefix}
        </Text>
        <Spacer height={24} />
        {/* Text component displaying the header title */}
        <Text type={TextTypes.Header} fontFamily={FontFamily.AttenNew}>
          {header.title}
        </Text>
        {/* Container for the logo image */}
        <div className={styles.logo}>
          {/* Image component displaying the logo */}
          <Image src={logo.file.url} alt={logo.file.fileName}></Image>
        </div>
      </Container>
      <ScrollerTop id="landingHome" scrollToRef={scrollToRef} />
    </Container>
  );
};
