import React from "react";
import styles from "./styles.module.scss";
import { TextProps } from "./types";
import { Colors, FontFamily } from "../../constants/default";

const Text: React.FC<TextProps> = ({
  type,
  children,
  className,
  color = Colors.PrimaryCharcoal,
  fontFamily = FontFamily.Satoshi,
  align = "center",
  verticalAlign,
  whiteSpace,
}) => {
  const textClass = styles[type] || styles.paragraph;

  return (
    <span
      className={`${styles.default} ${textClass} ${className}`}
      style={{
        color,
        fontFamily: fontFamily,
        textAlign: align,
        ...(verticalAlign ? { alignSelf: verticalAlign } : {}),
        ...(whiteSpace ? { whiteSpace } : {}),
      }}
    >
      {children}
    </span>
  );
};

export default Text;
