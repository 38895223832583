import { Colors } from "../../constants/default";
import { Container } from "../Container";
import LottieAnimation from "../Lottie";
import Loading from "../../assets/lottie/loading.json";

export const Loader = ({
  fluid = false,
  width = "500px",
  height = "500px",
}) => {
  return (
    <Container BG={Colors.PrimaryWine} height="100vh">
      <Container
        height={fluid ? "100%" : width}
        width={fluid ? "100%" : height}
      >
        <LottieAnimation animationData={Loading} />
      </Container>
    </Container>
  );
};
