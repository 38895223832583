import { Container } from "../../../components/Container";
import { Logo } from "../../../components/Logo";
import Text from "../../../components/Text";
import { Colors, FontFamily, TextTypes } from "../../../constants/default";
import { Item } from "../../../types";

export const ContentCard = ({
  data,
  display,
}: {
  data: {
    header: Item;
    contentList: Item[];
  };
  display?: "flex" | "block" | "none";
}) => {
  return (
    <Container
      BG={Colors.PrimaryWhite}
      borderRadius="20px"
      paddingVertical={48}
      paddingHorizontal={61}
      minWidth="30%"
      marginLeft={"50%"}
      alignSelf="stretch"
      display={display}
      mobileStyles={{
        paddingVertical: 24,
        paddingHorizontal: 40,
        marginLeft: 15,
        marginRight: 15,
        width: "100%",
        alignSelf: "center",
        maxWidth: "80%",
      }}
    >
      <Container
        flexDirection="row"
        justifyContent="space-between"
        // flexGrow={1}
        width="100%"
        paddingBottom={20}
      >
        <Text
          type={TextTypes.DefaultCardTitle}
          fontFamily={FontFamily.AttenNew}
          color={Colors.PrimaryWine}
        >
          {data.header.title}
        </Text>
        <Logo url={data.header.logo.file.url} width={50} height={50} />
      </Container>
      <Container
        gap={20}
        alignItems="stretch"
        flexGrow={1}
        justifyContent="flex-start"
        alignSelf="flex-start"
      >
        {data.contentList.map((content, index) => (
          <Container gap={20} alignSelf="flex-start">
            <Container
              key={index}
              flexDirection="row"
              gap={8}
              alignItems="center"
              alignSelf="flex-start"
            >
              <Logo url={content.logo.file.url} width={23} height={23} />
              <Text
                type={TextTypes.CardHeader}
                fontFamily={FontFamily.AttenNew}
                color={Colors.PrimaryWine}
                align="left"
              >
                {content.title}
              </Text>
            </Container>
            <Text
              type={TextTypes.CardContent}
              color={Colors.Black}
              align="left"
            >
              {content.subTitle}
            </Text>
          </Container>
        ))}
      </Container>
    </Container>
  );
};
