// AppContext.tsx
import React, { createContext, useState, ReactNode, useContext } from "react";
import { Languages } from "../services/translations/languages";

// Define a type for the context value
interface AppContextType {
  selectedLanguage: string;
  setSelectedLanguage: (option: string) => void;
}

// Create the context with a default value
const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const getDefaultValue = () => {
    const value = localStorage.getItem("language");
    if (value) {
      return value;
    } else {
      localStorage.setItem("language", Languages[0].value);
      return Languages[0].value;
    }
  };

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    getDefaultValue()
  );

  const setLanguage = (value: string) => {
    setSelectedLanguage(value);
    localStorage.setItem("language", value);
  };

  return (
    <AppContext.Provider
      value={{ selectedLanguage, setSelectedLanguage: setLanguage }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the AppContext
export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
