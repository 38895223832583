export const Content = {
  en: {
    title: "To ensure the best experience, please switch to the web version",
  },
  es: {
    title:
      "Para asegurar la mejor experiencia, por favor cambie a la versión web",
  },
  ar: {
    title: "لضمان أفضل تجربة، يرجى الانتقال إلى النسخة الإلكترونية",
  },
  de: {
    title:
      "Um die beste Erfahrung zu gewährleisten, wechseln Sie bitte zur Webversion",
  },
  fr: {
    title:
      "Pour garantir la meilleure expérience, veuillez passer à la version web",
  },
  pt: {
    title:
      "Para garantir a melhor experiência, por favor, mude para a versão web",
  },
  ru: {
    title:
      "Чтобы обеспечить лучший опыт, пожалуйста, переключитесь на веб-версию",
  },
};
