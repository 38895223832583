import { Colors, TextTypes } from "../constants/default";
import { Logo } from "./Logo";
import Text from "./Text";
import Sparkle from "../assets/png/sparkling.png";

export const Tag = ({ tag, color }: { tag?: string; color?: Colors }) => {
  return tag ? (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        padding: 3,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: "center",
        flexWrap: "nowrap",
        marginLeft: 5,
        alignSelf: "center",
        // border: `1px solid ${Colors.PrimaryWhite}`,
        // borderRadius: 5,
        marginBottom: 5,
      }}
    >
      <Text type={TextTypes.Tag} color={Colors.PrimaryWhite}>
        {tag}
      </Text>

      <Logo url={Sparkle} noPrefix width={20} height={20}></Logo>
    </div>
  ) : null;
};
