import { SrcPrefixUrl } from "../../constants/default";
import useIsMobile from "../../hooks/useMobile";
import styles from "./styles.module.scss";

export const BackgroundImage = ({
  src,
  children,
}: {
  src: string;
  children: React.ReactNode;
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={
        isMobile ? styles.imageBackgroundMobile : styles.imageBackground
      }
      style={{
        backgroundImage: `url(${SrcPrefixUrl}${src})`,
      }}
    >
      {children}
    </div>
  );
};
