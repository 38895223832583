// IframeComponent.tsx

import React, { useState } from "react";
import "./styles.scss";
import { Loader } from "../Loader";

interface IframeComponentProps {
  src: string;
}

const IframeComponent: React.FC<IframeComponentProps> = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);
  const handle = () => {
    setIsLoading(false);
  };

  return (
    <div className="iframe-container">
      {isLoading ? <Loader width="100%" height="700px" /> : null}
      <iframe
        title={src}
        src={src}
        onLoad={handle}
        style={{ display: isLoading ? "none" : "block" }}
      />
    </div>
  );
};

export default IframeComponent;
