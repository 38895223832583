import { Container } from "../../components/Container";
import { BackgroundImage } from "../../components/Image/Background";
import { DetailsCard } from "./components/DetailCards";
import { RatingReviewProps } from "./types";
import useIsMobile from "../../hooks/useMobile";

export const RatingReview = ({ data }: RatingReviewProps) => {
  const { colorPalette, backgroundImage, ratingContent } = data;
  const isMobile = useIsMobile();

  // Render the main container with a background color and height
  return (
    <Container
      BG={colorPalette.primary}
      // height="1600px"
      // position="relative"
      gap={isMobile ? 20 : 0}
    >
      {/* Render the background image with the rating content */}
      <BackgroundImage src={backgroundImage.file.url}>
        <DetailsCard ratingContent={ratingContent} />
      </BackgroundImage>

      {/* <VideoPlayer src={`${SrcPrefixUrl}${video.file.url}`} /> */}

      {/* <div
        className={
          isMobile ? styles.bottomContainerMobile : styles.bottomContainer
        }
      >
        <Text
          type={TextTypes.RatingFooterTitle}
          color={Colors.PrimartCharcoal50}
        >
          {ratingFooterTitle}
        </Text>

        <Container
          className={styles.certificates}
          BG={Colors.None}
          flexDirection="row"
          gap={isMobile ? 20 : 72}
          marginTop={57}
        >
          {certificates.map((certificate, index) => (
            <Logo
              key={index}
              url={certificate.file.url}
              width={144}
              height={146}
            />
          ))}
        </Container>
      </div> */}
    </Container>
  );
};
