export const Messages = {
  en: {
    button: "Navvan Home",
    "page-not-found": "PAGE NOT FOUND",
    "error-message":
      "It seems like the characters were spilled, and we can't find the webpage you're seeking. Please check the URL for typos, or use the navigation button below to find your way back to the Home screen.",
  },
  es: {
    button: "Inicio de Navvan",
    "page-not-found": "PÁGINA NO ENCONTRADA",
    "error-message":
      "Parece que los caracteres se han desbordado, y no podemos encontrar la página web que buscas. Por favor, revisa la URL en busca de errores tipográficos, o usa el botón de navegación a continuación para volver a la pantalla de inicio.",
  },
  ar: {
    button: "الصفحة الرئيسية لنافان",
    "page-not-found": "الصفحة غير موجودة",
    "error-message":
      "يبدو أن الأحرف قد انسكبت، ولا يمكننا العثور على صفحة الويب التي تبحث عنها. يرجى التحقق من عنوان URL بحثًا عن الأخطاء الإملائية، أو استخدم زر التنقل أدناه للعودة إلى شاشة الرئيسية.",
  },
  de: {
    button: "Navvan-Startseite",
    "page-not-found": "SEITE NICHT GEFUNDEN",
    "error-message":
      "Es scheint, dass die Zeichen übergelaufen sind, und wir können die Webseite, die Sie suchen, nicht finden. Bitte überprüfen Sie die URL auf Tippfehler oder verwenden Sie den Navigationsbutton unten, um zurück zum Startbildschirm zu gelangen.",
  },
  fr: {
    button: "Accueil de Navvan",
    "page-not-found": "PAGE NON TROUVÉE",
    "error-message":
      "Il semble que les caractères aient débordé, et nous ne pouvons pas trouver la page Web que vous recherchez. Veuillez vérifier l'URL pour des erreurs typographiques ou utiliser le bouton de navigation ci-dessous pour revenir à l'écran d'accueil.",
  },
  pt: {
    button: "Início da Navvan",
    "page-not-found": "PÁGINA NÃO ENCONTRADA",
    "error-message":
      "Parece que os caracteres transbordaram, e não conseguimos encontrar a página da web que você procura. Verifique a URL em busca de erros de digitação ou use o botão de navegação abaixo para voltar à tela inicial.",
  },
  ru: {
    button: "Главная Navvan",
    "page-not-found": "СТРАНИЦА НЕ НАЙДЕНА",
    "error-message":
      "Похоже, что символы переполнились, и мы не можем найти веб-страницу, которую вы ищете. Пожалуйста, проверьте URL на наличие опечаток или используйте кнопку навигации ниже, чтобы вернуться на главный экран.",
  },
};

export const MainLogo = {
  title: "navvan",
  description: "",
  file: {
    url: "//images.ctfassets.net/krs7svvea3qm/6IrOD1n9492B67DGimlOiR/fe41994a0a6f6a59ac2a81c5430c5557/withbg.png",
    details: {
      size: 74882,
      image: {
        width: 2256,
        height: 1116,
      },
    },
    fileName: "withbg.png",
    contentType: "image/png",
  },
};
