export enum ContentTypes {
  LandingHome = "landingHome",
  About = "about",
  RoleInformation = "roleInformation",
  WhyPage = "whyPage",
  Integrations = "integrations",
  RatingPage = "ratingPage",
  FaqPage = "faqPage",
  Testimonials = "testimonials",
}

export const Fields = "fields";
