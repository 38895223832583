const DevelopmentCredentials = {
  SPACE: "krs7svvea3qm",
  ACCESS_TOKEN: "d5eql2p3RacHtm4ed_y2WtrbYkM9aVm-hlRweY7XqJU",
  HOST: "preview.contentful.com",
};

const ProductionCredentials = {
  SPACE: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_DELIVERY_ACCESS_TOKEN,
  HOST: process.env.HOST,
};

export const ContentfulCredentials =
  process.env.NODE_ENV === "development"
    ? DevelopmentCredentials
    : ProductionCredentials;
