import { Container } from "../../components/Container";
import { RoleInformationProps } from "./types";
import Text from "../../components/Text";
import { Colors, FontFamily, TextTypes } from "../../constants/default";
import { Spacer } from "../../components/Spacer";
import { ScrollCardPage } from "./components/ScrollCard";

import Web from "../../assets/lottie/web.json";
import Mobile from "../../assets/lottie/mobile.json";
import UI from "../../assets/lottie/ui.json";
import AI from "../../assets/lottie/ai.json";

const Lotties = [Web, Mobile, UI, AI];

export const RoleInformation = ({ data }: RoleInformationProps) => {
  const { scrollCards, header, colorPalette } = data;

  return (
    <>
      <Container BG={colorPalette.primary} id={data.sectionId}>
        <Container
          mobileStyles={{
            paddingHorizontal: 20,
            paddingTop: 64,
            paddingBottom: 64,
          }}
          paddingHorizontal={350}
          paddingTop={128}
          paddingBottom={123}
        >
          <Text
            type={TextTypes.RatingFooterTitle}
            color={Colors.PrimartCharcoal50}
          >
            {header.titlePrefix}
          </Text>
          <Spacer height={24} />
          <Text type={TextTypes.Header} fontFamily={FontFamily.AttenNew}>
            {header.title}
          </Text>
          <Spacer height={15} />
          <Text type={TextTypes.SubTitle}>{header.subTitle}</Text>
        </Container>
      </Container>
      <div>
        {scrollCards.map((scrollCard, index) => {
          const previousCardBG = scrollCards[index - 1]?.colorPalette.primary;
          return (
            <div
              style={{
                backgroundColor: index ? previousCardBG : colorPalette.primary,
                position: "sticky",
                top: "-60vh",
              }}
            >
              <ScrollCardPage
                key={index}
                {...scrollCard}
                lottie={Lotties[index]}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
