import { Container } from "../../../components/Container";
import Link from "../../../components/Link";
import { Tag } from "../../../components/Tag";
import { Colors, TextTypes } from "../../../constants/default";
import { AppLevelTypes, LinkItem } from "../../../types";

export const FooterLinksList = ({
  headerLink,
  linksData,
  scrollToRef,
}: {
  headerLink: LinkItem;
  linksData: LinkItem[];
  scrollToRef: AppLevelTypes["scrollToRef"];
}) => {
  return (
    <Container
      gap={30}
      alignSelf="flex-start"
      justifyContent="flex-start"
      mobileStyles={{ marginRight: 10 }}
    >
      <Container alignSelf="flex-start" paddingRight={5}>
        <Link
          textType={TextTypes.FooterLinkslist}
          color={Colors.PrimaryWhite}
          navigatorFn={scrollToRef}
          {...headerLink}
        ></Link>
      </Container>

      <Container
        gap={20}
        alignSelf="flex-start"
        mobileStyles={{ marginTop: 25 }}
      >
        {linksData.map((link) => (
          <Container flexDirection="row" alignSelf="flex-start">
            <Link
              textType={TextTypes.FooterLinksListContent}
              color={Colors.PrimaryWhite}
              navigatorFn={scrollToRef}
              {...link}
            ></Link>
            <Tag tag={link.suffixTag} color={Colors.PrimaryWhite} />
          </Container>
        ))}
      </Container>
    </Container>
  );
};
