import { Colors } from "../../constants/default";
import { useAppContext } from "../../context/AppContext";
import { Languages } from "../../services/translations/languages";
import { LinkItem } from "../../types";
import { Container } from "../Container";
import { Dropdown } from "../Dropdown";
import Link from "../Link";
import { Tag } from "../Tag";
import styles from "./styles.module.scss";

export const Navbar = ({
  navbar,
  mainButton,
  scrollToRef,
}: {
  navbar: LinkItem[];
  mainButton: LinkItem;
  scrollToRef: (id: string) => void;
}) => {
  const { selectedLanguage, setSelectedLanguage } = useAppContext();
  return (
    <div className={styles.layout}>
      <Dropdown
        options={Languages}
        onSelect={(value) => {
          setSelectedLanguage(value);
        }}
        selectedValue={selectedLanguage}
        backgroundColor={Colors.PrimaryWine}
        textColor={Colors.PrimaryCharcoal}
      />
      {navbar.map((item) => (
        <Container
          flexDirection="row"
          alignSelf="flex-start"
          alignItems="center"
        >
          <Link navigatorFn={scrollToRef} {...item}></Link>

          <Tag tag={item.suffixTag}></Tag>
        </Container>
      ))}
      {/* {isMobile ? null : (
        <Button href={mainButton.href} title={mainButton.title}></Button>
      )} */}
    </div>
  );
};
