// src/hooks/useAnalytics.ts
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-X328YQSCV8", {
        page_path: location.pathname,
      });
    }
  }, [location]);
};

export default useAnalytics;
