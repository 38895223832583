import LottieAnimation from "./Lottie";
import Up from "../assets/lottie/up.json";
import useIsMobile from "../hooks/useMobile";

export const ScrollerTop = ({
  scrollToRef,
  id,
  styles,
  rightAlign,
}: {
  scrollToRef: (id: string) => void;
  id: string;
  styles?: any;
  rightAlign?: boolean;
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        ...(isMobile
          ? { width: "50px", height: "50px" }
          : { width: "100px", height: "100px" }),
        cursor: "pointer",
        ...(rightAlign
          ? { right: 0 }
          : { left: "50%", transform: "translateX(-50%)" }),
        ...styles,
      }}
      onClick={() => scrollToRef(id ?? "landingHome")}
    >
      <LottieAnimation animationData={Up}></LottieAnimation>
    </div>
  );
};
