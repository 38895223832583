import { LogoDimensions } from "../../constants/default";
import Image from "../Image";
import { LogoProps } from "./types";

export const Logo = ({
  url,
  width = LogoDimensions.Width,
  height = LogoDimensions.Height,
  noPrefix = false,
}: LogoProps) => {
  return (
    <Image
      noPrefix={noPrefix}
      src={url}
      alt={`logo${url}`}
      width={width}
      height={height}
    />
  );
};
