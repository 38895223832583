import "../../services/client";
import "../../styles/styles.scss";
import { useGetData } from "../../hooks/useData";
import { DefaultAppMapping } from "../../constants/appMapping";
import { Loader } from "../../components/Loader";
import useIsMobile from "../../hooks/useMobile";
import { Modal } from "../../components/Modal";
import { useEffect, useState } from "react";
import { Colors, FontFamily, TextTypes } from "../../constants/default";
import Text from "../../components/Text";
import { Container } from "../../components/Container";
import { useAppContext } from "../../context/AppContext";
import { Content } from "./constants";

export const Home = () => {
  // Fetch data using the useGetData hook
  const { selectedLanguage } = useAppContext();

  const { isLoading, dataList } = useGetData({
    appMapping: DefaultAppMapping,
    selectedLanguage,
  });
  const scrollToRef = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Function to render the view based on the fetched data
  const getRenderView = () => {
    const length = dataList.length;

    // If data is available
    if (length) {
      const firstPage = dataList[0];
      let restPagesView = null;

      // If there are more than one pages of data
      if (length > 1) {
        const restPages = dataList.slice(1);
        restPagesView = restPages.map((data: any, index) => {
          // Get the component based on the index
          const Component =
            DefaultAppMapping.ContentTypeList[index + 1].Component;
          return (
            <Component key={index} data={data} scrollToRef={scrollToRef} />
          );
        });
      }

      // Get the component for the first page
      const FirstPageView = DefaultAppMapping.ContentTypeList[0].Component;

      // Render the first page component and the rest of the pages
      return (
        <>
          <FirstPageView data={firstPage as any} scrollToRef={scrollToRef} />
          {restPagesView}
        </>
      );
    }

    // If no data is available, return null
    return null;
  };
  const isMobile = useIsMobile();
  const [switchToModal, setSwitchToModal] = useState(isMobile);
  useEffect(() => {
    setSwitchToModal(isMobile);
  }, [isMobile]);
  return (
    <div>
      <Modal
        isOpen={switchToModal}
        onClose={() => setSwitchToModal(false)}
        backgroundColor={Colors.Lightest}
      >
        <Container height="100%">
          <Text
            type={TextTypes.RatingFooterTitle}
            fontFamily={FontFamily.AttenNew}
          >
            {Content[selectedLanguage as keyof typeof Content]?.title}
          </Text>
        </Container>
      </Modal>

      {/* Show loader if data is loading and no data is available */}
      {isLoading && !dataList.length ? <Loader /> : <>{getRenderView()}</>}
    </div>
  );
};
