import { Container } from "../../components/Container";
import { Header } from "../../components/Header";
import Text from "../../components/Text";
import { Colors, FontFamily, TextTypes } from "../../constants/default";
import { LandingHomeProps } from "./types";
import LottieAnimation from "../../components/Lottie";
import styles from "./styles.module.scss";
import Button from "../../components/Button";
import { Footer } from "./components/Footer";
import { Spacer } from "../../components/Spacer";
import Loader from "../../assets/lottie/loading.json";

export const LandingHome = ({ data, scrollToRef }: LandingHomeProps) => {
  const { logo, navbar, mainButton, title, subTitle, footerTab, sectionId } =
    data;

  // Render the main container with a beige background color
  return (
    <Container BG={Colors.PrimaryBeige} id={sectionId}>
      {/* Render the header component with the provided logo, navbar, and mainButton */}
      <Header
        logo={logo}
        navbar={navbar}
        mainButton={mainButton}
        scrollToRef={scrollToRef}
      />
      {/* Add a vertical spacer with a height of 86 pixels */}
      <Spacer height={86} />
      {/* Render a container with a maximum width of 852 pixels and center-aligned content */}
      <Container alignItems={"center"}>
        {/* Render the title text using the specified font family */}
        <Text type={TextTypes.Header} fontFamily={FontFamily.AttenNew}>
          {title}
        </Text>
        {/* Render the subtitle text with a spaced style, using the specified font family and color */}
        <Text
          type={TextTypes.SubTitleSpaced}
          fontFamily={FontFamily.AttenNew}
          color={Colors.PrimaryGray}
        >
          {subTitle}
        </Text>
        {/* Render a button with the provided title, href, and a margin of 41 pixels from the top */}
        <Button {...mainButton} margin={{ top: 41 }} />
        <Container maxWidth={852} alignItems={"center"}>
          <div className={styles.lottie}>
            {/* Render the LottieAnimation component with the provided animation data */}
            <LottieAnimation animationData={Loader} />
          </div>
        </Container>
      </Container>
      {/* Render the footer component with the provided footerTab */}
      <Footer footerTab={footerTab} />
    </Container>
  );
};
