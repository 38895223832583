import { Container } from "../../components/Container";
import { Logo } from "../../components/Logo";
import { ScrollerTop } from "../../components/ScrollerTop";
import Text from "../../components/Text";
import { Colors, TextTypes } from "../../constants/default";
import { FooterLinksList } from "./components/FooterLinksList";
import { AboutPageProps } from "./types";

export const AboutPage = ({ data, scrollToRef }: AboutPageProps) => {
  const { logoWatermark, footerDataList, footerLinksList } = data;

  return (
    <Container
      flexDirection="row"
      BG={Colors.PrimaryBeige}
      justifyContent="space-between"
      paddingHorizontal={100}
      paddingVertical={70}
      alignSelf="flex-start"
      mobileStyles={{
        flexWrap: "wrap",
        paddingHorizontal: 50,
        paddingVertical: 35,
        gap: 40,
      }}
      position="relative"
      id={data.sectionId}
    >
      <Container
        justifyContent="space-between"
        alignSelf="stretch"
        mobileStyles={{
          // flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gap: 20,
        }}
      >
        <Logo url={logoWatermark.file.url} height={40} width={80} />
        {footerDataList.map((item) => (
          <Text
            type={TextTypes.footerDataList}
            color={Colors.PrimaryWhite}
            align="left"
          >
            {item}
          </Text>
        ))}
      </Container>
      <Container
        flexDirection="row"
        gap={90}
        mobileStyles={{ justifyContent: "space-between", width: "100%" }}
      >
        {footerLinksList.map(({ headerLink, linksData }) => (
          <FooterLinksList
            headerLink={headerLink}
            linksData={linksData}
            scrollToRef={scrollToRef}
          />
        ))}
      </Container>

      <ScrollerTop
        scrollToRef={scrollToRef}
        id={"landingHome"}
        styles={{ left: "50%", transform: "translateX(-50%)" }}
      />
    </Container>
  );
};
