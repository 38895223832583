import React, { useEffect, useState } from "react";
import ReactMarkdown, { Components } from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from "./styles.module.scss";
import { Colors, TextTypes } from "../../constants/default";
import { Container } from "../../components/Container";
import { LinkItem, Logo } from "../../types";
import { getData } from "../../services/getData";
import { Fields } from "../../constants/contentful/contentTypes";
import Image from "../../components/Image";
import { Loader } from "../../components/Loader";
import Text from "../../components/Text";
import { Spacer } from "../../components/Spacer";
import { Modal } from "../../components/Modal";
import { Header } from "../../components/Header";
import { MainLogo } from "../../components/common/constants";

interface Blog {
  title: string;
  content: string;
  thumbnail: Logo;
  socialMediaLinks: LinkItem[];
}

const customStyle = {
  ...solarizedlight,
  'pre[class*="language-"]': {
    backgroundColor: Colors.LightBG, // Change this color to your desired background color
    borderRadius: "4px",
    padding: "20px",
  },
};

interface BlogPostProps {
  title?: string;
  content?: string; // Assuming content is in Markdown format
}

const BlogPost: React.FC<BlogPostProps> = ({ title, content }) => {
  const renderers: Components = {
    code(props) {
      const { className, children } = props;
      const language = className?.replace(/language-/, "") || "";
      const value = children ? String(children).replace(/\n$/, "") : "";

      return language ? (
        <SyntaxHighlighter
          style={customStyle} // Use the custom style
          language={language}
          PreTag="div"
          customStyle={{ borderRadius: "4px", margin: "20px 0" }}
        >
          {value}
        </SyntaxHighlighter>
      ) : (
        <code className={styles.inlineCode}>{value}</code>
      );
    },
  };

  return (
    <article className={styles.blogPost}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.metadata}>
        {/* <span className={styles.author}>{author}</span>
        <span className={styles.date}>{date}</span> */}
      </div>
      <div className={styles.content}>
        <ReactMarkdown components={renderers}>{content}</ReactMarkdown>
      </div>
    </article>
  );
};

const Blogs = () => {
  const [blogs, setBlogs] = useState<{ [key: string]: Blog[] }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState<Blog | null>(null);
  useEffect(() => {
    getData({
      content_type: "blogs",
      select: Fields,
    })
      .then((response) => {
        delete response[0]["sectionId"];
        console.log("response======>", response[0]);
        setBlogs(response[0]);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, []);

  const openModal = (blog: Blog) => {
    setSelectedBlog(blog);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedBlog(null);
    setModalIsOpen(false);
  };

  return (
    <Container BG={Colors.PrimaryWine}>
      <Header logo={MainLogo} scrollToRef={() => {}} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {Object.keys(blogs).map((key) => {
            return (
              <>
                <Spacer height={40} />
                <Text
                  type={TextTypes.RatingFooterTitle}
                  className={styles.text}
                >
                  {key.toUpperCase()}
                </Text>
                <Spacer height={40} />
                <Container
                  BG={Colors.PrimaryWine}
                  paddingHorizontal={40}
                  paddingVertical={40}
                  height="100%"
                  flexDirection="row"
                  flexWrap="wrap"
                  gap={40}
                  justifyContent="space-evenly"
                >
                  {blogs[key].map((blog, index) => {
                    return (
                      <div
                        key={index}
                        className={styles.blogCard}
                        onClick={() => openModal(blog)}
                      >
                        <div className={styles.cardInner}>
                          <div className={styles.cardFront}>
                            <Image
                              src={blog.thumbnail?.file.url}
                              alt={blog.title}
                            />
                          </div>
                          <div
                            className={styles.cardBack}
                            style={{ backgroundColor: Colors.PrimaryCharcoal }}
                          >
                            <h3>{blog.title}</h3>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Container>
              </>
            );
          })}
          <Modal
            isOpen={modalIsOpen}
            onClose={closeModal}
            backgroundColor={Colors.Lightest}
            width="50vw"
            height="80vh"
          >
            <BlogPost
              title={selectedBlog?.title}
              content={selectedBlog?.content}
            />
          </Modal>
        </>
      )}
    </Container>
  );
};

export default Blogs;
