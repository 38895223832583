import React from "react";
import styles from "./styles.module.scss";
import { ButtonProps } from "./types";
import { Colors, SrcPrefixUrl, TextTypes } from "../../constants/default";
import Link from "../Link";
import { useNavigate } from "react-router-dom";

const Button: React.FC<ButtonProps> = ({
  onClick,
  title,
  className,
  style,
  disabled = false,
  href,
  margin,
  isFormRoute,
  formRouteName,
}) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        if (isFormRoute) {
          navigate(`/user-form/${formRouteName}`);
        } else if (onClick) {
          onClick();
        } else {
          window.open(
            `${href.includes(SrcPrefixUrl) ? href : `${SrcPrefixUrl}${href}`}`,
            "_blank"
          );
        }
      }}
      className={`${styles.button} ${className}`}
      style={{
        ...style,
        marginTop: `${margin?.top}px`,
        marginBottom: `${margin?.bottom}px`,
        marginLeft: `${margin?.left}px`,
        marginRight: `${margin?.right}px`,
      }}
      disabled={disabled}
    >
      <Link
        title={title}
        textType={TextTypes.Button}
        color={Colors.PrimaryWhite}
      ></Link>
    </button>
  );
};

export default Button;
