import { Colors } from "../../constants/default";
import styles from "./styles.module.scss";

export const VerticalSeparator = ({ thickness = 1, color = Colors.Stroke }) => {
  const style = {
    border: `solid ${color}`,
    borderRightWidth: `${thickness}px`,
  };

  return (
    <div className={styles.separator} style={style}>
      <div style={{ height: "100%" }}></div>
    </div>
  );
};

export const HorizontalSeparator = ({
  thickness = 1,
  color = Colors.Stroke,
}) => {
  const style = {
    border: `solid ${color}`,
    borderTopWidth: `${thickness}px`,
  };

  return <div className={styles.separator} style={style}></div>;
};
