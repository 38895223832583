import { useState } from "react";
import { ToggleItemProps } from "../types";
import { Logo } from "../../../components/Logo";
import Text from "../../../components/Text";
import { TextTypes } from "../../../constants/default";
import styles from "../styles.module.scss";
import { Container } from "../../../components/Container";
import useIsMobile from "../../../hooks/useMobile";

export const ToggleItem: React.FC<ToggleItemProps> = ({
  title,
  subTitle,
  toggleOpen,
  toggleClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  return (
    <Container alignSelf="flex-start">
      <div
        className={styles.faqHeader}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Container mobileStyles={{ width: "15%" }}>
          <div className={`${isOpen ? styles.logoOpen : styles.logoClosed}`}>
            <Logo
              url={isOpen ? toggleClose.file.url : toggleOpen.file.url}
              width={25}
              height={25}
            ></Logo>
          </div>
        </Container>
        <Text
          type={isMobile ? TextTypes.BulletLiContent : TextTypes.FaqTitle}
          align="left"
        >
          {title}
        </Text>
      </div>
      <div
        className={`${styles.faqContent} ${
          isOpen ? styles.faqContentOpen : ""
        }`}
      >
        <Text type={TextTypes.FaqContent}>{subTitle} </Text>
      </div>
    </Container>
  );
};
