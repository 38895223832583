import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767); // Initialize isMobile state based on window width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767); // Update isMobile state when window is resized
    };

    window.addEventListener("resize", handleResize); // Add event listener for window resize

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize); // Remove event listener when component is unmounted
    };
  }, []);

  return isMobile; // Return the current value of isMobile
};

export default useIsMobile;
