export const LogoDimensions = {
  Width: 64,
  Height: 64,
};

export const SrcPrefixUrl = "https:";

export enum TextTypes {
  BigHeader = "bigHeader",
  Header = "header",
  Link = "link",
  Button = "button",
  SubTitleSpaced = "subTitle-spaced",
  SubTitle = "subTitle",
  FooterTitle = "footerTitle",
  DefaultCardTitle = "detailsCardTitle",
  DefaultCardSubTitle = "detailsCardSubTitle",
  RatingFooterTitle = "ratingFooterTitle",
  HeaderSmall = "headerSmall",
  HeaderMedium = "headerMedium",
  SubTitleMedium = "subTitleMedium",
  BulletLi = "bulletLi",
  BulletLiContent = "bulletLiContent",
  BulletLiFirst = "bulletLiFirst",
  TabListTitle = "tabListTitle",
  CardHeader = "cardHeader",
  CardContent = "cardContent",
  FaqTitle = "faqTitle",
  FaqContent = "faqContent",
  footerDataList = "footerDataList",
  FooterLinksListContent = "footerLinksListContent",
  FooterLinkslist = "footerLinksList",
  Tag = "tag",
  Normal = "normal",
}

export enum Colors {
  PrimaryCharcoal = "#D6A553",
  CardBG = "#d6ba8c",
  PrimartCharcoal50 = "#8F9197",
  PrimaryGreen = "#D6A553",
  PrimaryWhite = "#FFFFFF",
  PrimaryBeige = "#673147",
  PrimaryGray = "#efdfc4",
  FooterTitle = "#D6A553",
  FooterBorderTopColor = "#D6A553",
  None = "none",
  Stroke = "#474E60",
  Black = "#000000",
  Separator = "#D9D7CF",
  PrimaryWine = "#673147",
  TextCard = "#99755c",
  CardContent = "#322d29",
  LightBG = "#F1F0ED",
  Lightest = "#efefe5",
}

export enum FontFamily {
  Satoshi = "Satoshi",
  AttenNew = "AttenNew",
}

export const TabListSwitchTimer = 8000;
