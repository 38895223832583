// src/hooks/useScrollTracking.ts
import { useEffect } from "react";

const useScrollTracking = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;
      const windowHeight = window.innerHeight;

      const scrollPercent = Math.round(
        (scrollY / (documentHeight - windowHeight)) * 100
      );

      if (scrollPercent >= 25 && scrollPercent < 50) {
        window.gtag("event", "scroll_depth", {
          event_category: "Engagement",
          event_label: "25%",
          value: 25,
        });
      } else if (scrollPercent >= 50 && scrollPercent < 75) {
        window.gtag("event", "scroll_depth", {
          event_category: "Engagement",
          event_label: "50%",
          value: 50,
        });
      } else if (scrollPercent >= 75 && scrollPercent < 100) {
        window.gtag("event", "scroll_depth", {
          event_category: "Engagement",
          event_label: "75%",
          value: 75,
        });
      } else if (scrollPercent === 100) {
        window.gtag("event", "scroll_depth", {
          event_category: "Engagement",
          event_label: "100%",
          value: 100,
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
};

export default useScrollTracking;
