import styled from "styled-components";

const StyledLink = styled.div`
  text-decoration: none;
  position: relative;
  color: ${(props) => props.color};
  padding-bottom: 4px;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.color};
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export default StyledLink;
