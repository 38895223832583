// User.tsx
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "../../components/Container";
import IframeComponent from "../../components/IFrame";
import { UserFormsMap, UserFormsMapKeys } from "./constants";
import { Colors, FontFamily, TextTypes } from "../../constants/default";
import Button from "../../components/Button";
import Text from "../../components/Text";
import { Spacer } from "../../components/Spacer";
import NotFound from "../../components/common/NotFound";
import { useAppContext } from "../../context/AppContext";

export const UserForms: React.FC = () => {
  const { formId } = useParams<{ formId: UserFormsMapKeys }>();
  const navigate = useNavigate();
  const { selectedLanguage } = useAppContext();

  return !(UserFormsMap as any)[selectedLanguage as any][formId as any] ? (
    <NotFound />
  ) : (
    <Container
      BG={Colors.PrimaryWine}
      width="100vw"
      height="100vh"
      justifyContent="space-between"
      flexDirection="row"
      mobileStyles={{
        flexDirection: "column",
        paddingVertical: 40,
        gap: 40,
      }}
    >
      <Container flexGrow={1} height="100%" paddingHorizontal={20}>
        <IframeComponent
          src={
            (UserFormsMap as any)[selectedLanguage as any][
              formId ?? UserFormsMapKeys.JoinTheTeam
            ].link
          }
        />
      </Container>
      <Container
        flexGrow={1}
        height="100%"
        paddingHorizontal={20}
        justifyContent="space-evenly"
        maxWidth={"35%"}
        mobileStyles={{ maxWidth: "100%" }}
      >
        <Container>
          <Text type={TextTypes.Header} fontFamily={FontFamily.AttenNew}>
            {
              (UserFormsMap as any)[selectedLanguage as any][
                formId ?? UserFormsMapKeys.JoinTheTeam
              ].title
            }
          </Text>
          <Spacer height={24} />
          <Spacer height={24} />
          <Text type={TextTypes.SubTitle}>
            {
              (UserFormsMap as any)[selectedLanguage as any][
                formId ?? UserFormsMapKeys.JoinTheTeam
              ].subTitle
            }
          </Text>
        </Container>

        <Spacer height={24} />
        <Container>
          <Text
            type={TextTypes.RatingFooterTitle}
            color={Colors.PrimartCharcoal50}
          >
            {
              (UserFormsMap as any)[selectedLanguage as any][
                formId ?? UserFormsMapKeys.JoinTheTeam
              ].description
            }
          </Text>
          <Spacer height={24} />
          <Container>
            <Button
              title={(UserFormsMap as any)[selectedLanguage as any]["button"]}
              onClick={() => navigate("/home")}
              href=""
            />
          </Container>
        </Container>
      </Container>
    </Container>
  );
};
