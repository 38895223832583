import { AboutPage } from "../pages/about";
import { FaqPage } from "../pages/faq";
import { Integrations } from "../pages/integrations";
import { LandingHome } from "../pages/landing";
import { OwnerNotes } from "../pages/OwnerNotes";
import { RatingReview } from "../pages/review";
import { RoleInformation } from "../pages/role";
import { WhyPage } from "../pages/why";
import { ContentTypes } from "./contentful/contentTypes";

export const DefaultAppMapping = {
  ContentTypeList: [
    { contentType: ContentTypes.LandingHome, Component: LandingHome },
    {
      contentType: ContentTypes.RatingPage,
      Component: RatingReview,
    },
    {
      contentType: ContentTypes.RoleInformation,
      Component: RoleInformation,
    },
    {
      contentType: ContentTypes.WhyPage,
      Component: WhyPage,
    },
    {
      contentType: ContentTypes.Integrations,
      Component: Integrations,
    },
    {
      contentType: ContentTypes.FaqPage,
      Component: OwnerNotes,
    },
    {
      contentType: ContentTypes.FaqPage,
      Component: FaqPage,
    },
    // {
    //   contentType: ContentTypes.Testimonials,
    //   Component: Carousel,
    // },
    {
      contentType: ContentTypes.About,
      Component: AboutPage,
    },
  ],
};

export interface DefaultAppMappingType {
  ContentTypeList: {
    contentType: ContentTypes;
    Component: React.FC<any>;
  }[];
}
