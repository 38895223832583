import React from "react";
import Lottie from "lottie-react";
import { LottieAnimationProps } from "./types";
import styles from "./styles.module.css";

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  loop = true,
  autoplay = true,
  style,
  animationData,
  width,
  height,
}) => {
  return (
    <Lottie
      animationData={animationData}
      loop={loop}
      autoplay={autoplay}
      style={{ ...style, ...styles }}
      width={width}
      height={height}
    />
  );
};

export default LottieAnimation;
