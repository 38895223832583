import { Logo } from "../Logo";
import { Navbar } from "../Navbar";
import { HeaderProps } from "./types";
import styles from "./styles.module.scss";

export const Header = ({
  logo,
  navbar,
  mainButton,
  scrollToRef,
}: HeaderProps) => {
  return (
    <div className={styles.layout}>
      <div style={{ alignSelf: "flex-start" }}>
        <Logo url={logo.file.url} width={140} height={70}></Logo>
      </div>

      {navbar && mainButton ? (
        <Navbar
          navbar={navbar}
          mainButton={mainButton}
          scrollToRef={scrollToRef}
        ></Navbar>
      ) : null}
    </div>
  );
};
