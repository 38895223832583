import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import StyledLink from "../Link/LinkStyled";
import Text from "../Text";
import { Colors, TextTypes } from "../../constants/default";
import { Container } from "../Container";

interface DropdownOption {
  label: string;
  value: string;
  flag: any;
}

interface DropdownProps {
  options: DropdownOption[];
  selectedValue: string;
  onSelect: (value: string) => void;
  placeholder?: string;
  backgroundColor?: string;
  textColor?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedValue,
  onSelect,
  placeholder = "Select an option",
  backgroundColor = "#fff",
  textColor = "#000",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (value: string) => {
    onSelect(value);
    setIsOpen(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const getSelectedValue = () => {
    if (selectedValue) {
      const value = options.find((option) => option.value === selectedValue);
      return `${value?.flag} ${value?.label}`;
    }
    return placeholder;
  };
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const handleScroll = () => {
      setIsOpen(false);
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
      window.addEventListener("scroll", handleScroll);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, setIsOpen]);
  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      <div
        className={styles.dropdownToggle}
        style={{ backgroundColor, color: textColor }}
      >
        <Container
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <StyledLink onClick={handleToggle} color={textColor}>
            <Text type={TextTypes.Link} color={textColor as Colors}>
              {getSelectedValue()}
            </Text>
          </StyledLink>
        </Container>
      </div>
      {isOpen && (
        <div
          className={`${styles.dropdownMenu} ${isOpen ? styles.open : ""}`}
          style={{ backgroundColor }}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className={styles.dropdownOption}
              onClick={() => handleSelect(option.value)}
              style={{ color: textColor }}
            >
              {`${option?.flag} ${option?.label}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
