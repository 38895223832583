export const Languages = [
  {
    label: "English",
    value: "en",
    flag: "🇺🇸",
  },
  { label: "Arabic", value: "ar", flag: "🇦🇪" },
  {
    label: "French",
    value: "fr",
    flag: "🇫🇷",
  },
  // { label: "German", value: "au",flag:"🇩🇪" },
  { label: "Spanish", value: "es", flag: "🇪🇸" },
  { label: "Portuguese", value: "pt", flag: "🇧🇷" },
  // { label: "Russian", value: "ru", flag: ":🇷🇺" },
];
export type LanguageCode = "en" | "ar" | "fr" | "au" | "es" | "pt" | "ru";
