import AppRouter from "./containers/AppRouter";
import * as Sentry from "@sentry/react";
import { AppProvider } from "./context/AppContext";
import { BrowserRouter as Router } from "react-router-dom";

Sentry.init({
  dsn: "https://60c177e6bebe4874521235a944f7a0b4@o4508132616110080.ingest.us.sentry.io/4508132618338304",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const App = () => {
  return (
    <AppProvider>
      <Router>
        <AppRouter />
      </Router>
    </AppProvider>
  );
};
