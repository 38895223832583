import { Container } from "../../components/Container";
import { Spacer } from "../../components/Spacer";
import Text from "../../components/Text";
import { Colors, FontFamily, TextTypes } from "../../constants/default";
import { ContentCard } from "./components/ContentCard";
import { WhyPageProps } from "./types";
import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import useIsMobile from "../../hooks/useMobile";
import { ScrollerTop } from "../../components/ScrollerTop";

export const WhyPage = ({ data, scrollToRef }: WhyPageProps) => {
  const { header, colorPalette, contentCards, sectionId } = data;

  const isMobile = useIsMobile();

  const trackRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [direction, setDirection] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const [positionState, setPosition] = useState(0);

  useEffect(() => {
    const track = trackRef.current;
    const container = containerRef.current;
    let position = positionState;
    let animationFrameId: number;

    const scrollStep = 1; // Slower speed for smoother transition
    const pauseDuration = 200; // Pause for 2 seconds at edges

    const animateScroll = () => {
      if (track && container) {
        const trackWidth = track.scrollWidth;
        const containerWidth = container.clientWidth;
        const maxScrollLeft = trackWidth - containerWidth;

        // Update position based on direction
        position += direction * scrollStep;
        setPosition(position);

        track.style.transform = `translateX(${-position}px)`; // Negative to move left

        // When right-most card is fully visible
        if (position >= maxScrollLeft + 60) {
          setIsPaused(true);
          setTimeout(() => {
            setDirection(-1); // Reverse direction
            setIsPaused(false);
          }, pauseDuration);
        }

        // When left-most card is fully visible
        if (position <= -60) {
          setIsPaused(true);
          setTimeout(() => {
            setDirection(1); // Reverse direction
            setIsPaused(false);
          }, pauseDuration);
        }

        // Only continue if not paused
        if (!isPaused) {
          animationFrameId = requestAnimationFrame(animateScroll);
        }
      }
    };

    // Start the animation
    animationFrameId = requestAnimationFrame(animateScroll);

    return () => {
      // Cancel animation on component unmount
      cancelAnimationFrame(animationFrameId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction, isPaused]);

  return (
    <Container
      BG={colorPalette.primary}
      paddingBottom={179}
      mobileStyles={{ paddingBottom: 90 }}
      id={sectionId}
      position="relative"
    >
      <Container
        paddingHorizontal={350}
        paddingTop={128}
        paddingBottom={123}
        mobileStyles={{
          paddingHorizontal: 20,
          paddingTop: 62,
          paddingBottom: 62.5,
        }}
      >
        <Text
          type={TextTypes.RatingFooterTitle}
          color={Colors.PrimartCharcoal50}
        >
          {header.titlePrefix}
        </Text>
        <Spacer height={24} />
        <Text type={TextTypes.Header} fontFamily={FontFamily.AttenNew}>
          {header.title}
        </Text>
        <Spacer height={15} />
        <Text type={TextTypes.SubTitle}>{header.subTitle}</Text>
      </Container>
      {isMobile ? (
        <Container gap={20} paddingHorizontal={20}>
          {[...contentCards].map((card, index) => (
            <ContentCard key={index} data={card} />
          ))}
        </Container>
      ) : (
        <div
          className={styles.carouselContainer}
          ref={isMobile ? null : containerRef}
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <div
            className={styles.cardContainerAuto}
            ref={isMobile ? null : trackRef}
          >
            {[...contentCards].map((card, index) => (
              <ContentCard key={index} data={card} />
            ))}
          </div>
        </div>
      )}
      <ScrollerTop scrollToRef={scrollToRef} id="landingHome" />
    </Container>
  );
};
