import Button from "../../../components/Button";
import { Container } from "../../../components/Container";
import LottieAnimation from "../../../components/Lottie";
import { ScrollCard } from "../types";
import { CardContent } from "./CardContent";
import { TabList } from "./TabList";

export const ScrollCardPage = ({
  header,
  content,
  action,
  tabList,
  colorPalette,
  lottie,
}: ScrollCard) => {
  return (
    // Outer container with scrollable overflow
    <Container
      BG={colorPalette.primary}
      borderTopLeftRadius={"40px"}
      borderTopRightRadius="40px"
      overflow="scroll"
    >
      {/* Container for card content and animation */}
      <Container
        marginTop={150}
        marginLeft={150}
        marginRight={100}
        gap={90}
        flexDirection="row"
        mobileStyles={{
          flexWrap: "wrap",
          marginTop: 50,
          marginLeft: 50,
          marginRight: 50,
          gap: 45,
        }}
        alignSelf="stretch"
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        {/* Card content */}
        <CardContent
          header={header}
          content={content}
          colorPalette={colorPalette}
        ></CardContent>
        {/* Animation container */}
        <Container
          alignSelf="flex-start"
          mobileStyles={{ maxWidth: "90%" }}
          maxWidth={"40%"}
        >
          <LottieAnimation animationData={lottie}></LottieAnimation>
        </Container>
      </Container>
      {/* Container for tab list */}
      <Container
        marginTop={116}
        marginRight={94.5}
        marginLeft={94.5}
        mobileStyles={{
          marginTop: 58,
          marginRight: 47.25,
          marginLeft: 47.25,
          overflow: "hidden",
        }}
      >
        <TabList tabList={tabList} colorPalette={colorPalette} />
      </Container>
      {/* Container for action button */}
      <Container
        marginBottom={126}
        marginTop={82}
        mobileStyles={{
          marginBottom: 63,
          marginTop: 41,
        }}
      >
        {/* Action button */}
        <Button {...action}></Button>
      </Container>
    </Container>
  );
};
